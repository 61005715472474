import { postRequest, showElement } from "./utils";

interface NewsletterConfirmRequest {
  confirmKey: string;
}

export const initNewsletterConfirm = () => {
  const searchParams = new URLSearchParams(location.search);
  const confirmKey = searchParams.get("confirmKey");
  if (!confirmKey) return;
  const successMessage = document.querySelector(
    ".js-newsletter-confirm-success-message"
  );
  const errorMessage = document.querySelector(
    ".js-newsletter-confirm-error-message"
  );

  if (!successMessage || !errorMessage) return;

  const data: NewsletterConfirmRequest = {
    confirmKey,
  };

  postRequest({
    url: "/api/newsletter/confirm",
    data,
    onError: () => {
      showElement(errorMessage);
    },
    onSuccess: () => {
      showElement(successMessage);
    },
  });
};
