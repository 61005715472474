import { UsercentricsEvent, WindowWithUsercentrics } from "./@types/types";

export const handleUsercentricsButtons = () => {
  document
    .querySelector('[href="#show-usercentrics-modal"]')
    ?.addEventListener("click", (event) => {
      event.preventDefault();
      (<WindowWithUsercentrics>(<unknown>window)).UC_UI?.showSecondLayer();
    });
};

const getIsUsercentricsEvent = (event: Event): event is UsercentricsEvent =>
  (<UsercentricsEvent>event)?.detail?.event === "consent_status";

export const addUsercentricsConsentEventListener = (
  callback: (event: UsercentricsEvent) => void
) =>
  window.addEventListener(
    "UsercentricsCustomEvent",
    (event) => getIsUsercentricsEvent(event) && callback(event)
  );
