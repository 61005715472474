import baguetteBox from "baguettebox.js";
import Embla from "embla-carousel";
import { baguetteBoxOptions, getRandomInteger } from "./utils";

export const initPhotoGallerySlider = () => {
  const element = document.querySelector<HTMLElement>(
    ".js-photo-gallery-slider"
  );
  if (!element) return;

  const slider = Embla(element, { align: "center", loop: true });

  const prevButton = element.querySelector<HTMLButtonElement>(
    ".js-photo-gallery-nav-prev"
  );
  const nextButton = element.querySelector<HTMLButtonElement>(
    ".js-photo-gallery-nav-next"
  );

  prevButton?.addEventListener("click", () => slider.scrollPrev());
  nextButton?.addEventListener("click", () => slider.scrollNext());

  baguetteBox.run(".js-photo-gallery-slider", baguetteBoxOptions);
};

export const initSimpleSliders = () => {
  const elements = Array.from(document.querySelectorAll(".js-slider-outer"));

  elements.forEach((element) => {
    const sliderElement = element.querySelector<HTMLElement>(".js-slider");
    if (!sliderElement) return;

    // Abort if there are no slides, otherwise Embla would throw an error.
    const count =
      sliderElement.querySelector(".embla-container")?.childElementCount ?? 0;
    if (!count) return;

    const slider = Embla(sliderElement, { align: "start", loop: true });

    const showRandomSlide = Boolean(
      sliderElement.dataset["initialRandomSlide"]
    );
    if (showRandomSlide) {
      const randomSlideIndex = getRandomInteger(0, slider.slideNodes().length);
      slider.scrollTo(randomSlideIndex, true);
    }

    element
      .querySelector(".js-slider-nav-prev")
      ?.addEventListener("click", () => slider.scrollPrev());
    element
      .querySelector(".js-slider-nav-next")
      ?.addEventListener("click", () => slider.scrollNext());
  });

  // Important: this needs to be run after the event listener
  // with `stopImmediatePropagation` is called
  baguetteBox.run(".js-lightbox-gallery", baguetteBoxOptions);
};
