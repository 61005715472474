import "@fontsource/domine/latin-400.css";
import "@fontsource/domine/latin-700.css";
import "@fontsource/lato/latin-400.css";
import "@fontsource/lato/latin-700.css";
import "../css/entry.css";
import registerErrorReporting from "./error-reporting";
import { handleMapLoad } from "./google-maps";
import { initNewsletterConfirm } from "./init-newsletter-confirm";
import { initPodcasts } from "./init-podcasts";
import {
  handleMobileMainMenu,
  handleNarrowFixedHeader,
  handleWideHeader,
} from "./main-menu";
import { initPhotoGallerySlider, initSimpleSliders } from "./sliders";
import {
  addUsercentricsConsentEventListener,
  handleUsercentricsButtons,
} from "./usercentrics";
import { isTouchDevice } from "./utils";
import { handleVideoClick } from "./video";

registerErrorReporting();

/**
 * The single click handler for the entire document. All click events should
 * use this function with event delegation.
 */
const documentClickHandler = () => {
  document.addEventListener("click", (event: MouseEvent) => {
    const clickedElement = <HTMLElement>event.target;

    preventTopLevelMenuClickOnTouch(event, clickedElement);
    handleVideoClick(clickedElement);
  });
};

const preventTopLevelMenuClickOnTouch = (
  event: MouseEvent,
  element: HTMLElement
) => {
  const menuItem = element.closest(".js-main-menu-link-top-level");
  menuItem &&
    isTouchDevice &&
    window.matchMedia("(min-width: 1440px)").matches &&
    event.preventDefault();
};

let newsletterFormLoaded = false;
const handleNewsletterFormLoad = async (el: HTMLElement) => {
  if (!el.matches("#newsletter-form") || newsletterFormLoaded) return;
  const { initNewsletterForm } = await import("./init-newsletter-form");
  initNewsletterForm(el);
  newsletterFormLoaded = true;
};

let courseRegistrationLoaded = false;
const handleCourseRegistrationFormLoad = async (el: HTMLElement) => {
  if (!el.matches(".js-course-registration-form") || courseRegistrationLoaded)
    return;

  const { initCourseRegistrationForm } = await import(
    "./init-course-registration-form"
  );

  initCourseRegistrationForm(el);
  courseRegistrationLoaded = true;
};

let rentalEnquiryLoaded = false;
const handleRentalEnquiryFormLoad = async (el: HTMLElement) => {
  if (!el.matches("#rental-enquiry-form") || rentalEnquiryLoaded) return;
  const { initRentalEnquiryForm } = await import("./init-rental-enquiry-form");
  initRentalEnquiryForm(el);
  rentalEnquiryLoaded = true;
};

let GourmetsTomorrowEnquiryLoaded = false;
const handleGourmetsTomorrowEnquiryFormLoad = async (el: HTMLElement) => {
  if (
    !el.matches("#gourmets-tomorrow-enquiry-form") ||
    GourmetsTomorrowEnquiryLoaded
  )
    return;
  const { initGourmetsTomorrowEnquiryForm } = await import(
    "./init-gourmets-tomorrow-enquiry-form"
  );
  initGourmetsTomorrowEnquiryForm(el);
  GourmetsTomorrowEnquiryLoaded = true;
};

let calenderLoaded = false;
const handleCalendarLoad = async (el: HTMLElement) => {
  if (!el.matches("#calendar") || calenderLoaded) return;
  const { initCalendar } = await import("./init-calendar");
  initCalendar(el);
  calenderLoaded = true;
};

let mapAllowed = false;
const lazyloadHandler = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      const target = <HTMLElement>entry.target;
      handleNewsletterFormLoad(target);
      handleCourseRegistrationFormLoad(target);
      handleRentalEnquiryFormLoad(target);
      handleGourmetsTomorrowEnquiryFormLoad(target);
      handleCalendarLoad(target);

      mapAllowed && handleMapLoad();
    });
  });

  document
    .querySelectorAll(".lazyload-container")
    .forEach((el) => observer.observe(el));
};

addUsercentricsConsentEventListener((event) => {
  const mapElement = document.querySelector(".js-map-container");
  event.detail["Google Maps"] &&
    mapElement?.classList.add("lazyload-container");
  mapAllowed = true;
});

const mainMenuOuter = document.querySelector<HTMLElement>(
  ".js-main-menu-outer"
);

mainMenuOuter && handleMobileMainMenu(mainMenuOuter);
mainMenuOuter && handleNarrowFixedHeader(mainMenuOuter);
handleWideHeader();

documentClickHandler();
lazyloadHandler();
initPhotoGallerySlider();
initNewsletterConfirm();
initSimpleSliders();
handleUsercentricsButtons();
initPodcasts();
