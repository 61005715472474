import { format } from "date-fns";
import { de, it } from "date-fns/locale";
import {
  CmsData,
  Country,
  CourseAPIResponse,
  FormFieldValidation,
  Language,
  TargetAudience,
} from "./@types/types";
import languagesJSON from "./languages.json";

export const postRequest = ({
  url,
  data,
  onSuccess,
  onError,
}: {
  url: string;
  data: object;
  onSuccess: () => void;
  onError: (requestStatus: number) => void;
}): void => {
  const request = new XMLHttpRequest();
  request.open("POST", url);
  request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

  request.onreadystatechange = () => {
    if (request.readyState !== 4) return;
    if (request.status >= 400) {
      onError(request.status);
      return;
    }
    onSuccess();
  };

  request.send(JSON.stringify(data));
};

export const getRequest = <Data>({
  url,
  onSuccess,
  onError,
}: {
  url: string;
  onSuccess: (data: Data) => void;
  onError: (requestStatus: number) => void;
}): void => {
  const request = new XMLHttpRequest();
  request.open("GET", url);
  request.onreadystatechange = () => {
    if (request.readyState !== 4) return;
    if (request.status >= 400) {
      onError(request.status);
      return;
    }
    onSuccess(JSON.parse(request.responseText));
  };

  request.send();
};

export const loadScript = (src: string, onload: () => void) => {
  let script = document.createElement("script");
  script.src = src;
  script.addEventListener("load", onload);
  document.head.appendChild(script);
};

export const dateLocales = { de, it };

/**
 * Hide an HTML element (add [hidden] attr)
 */
export const hideElement = (element: Element | null) =>
  element?.setAttribute("hidden", "");

/**
 * Show a HTML element (remove [hidden] attr)
 */
export const showElement = (element: Element | null) =>
  element?.removeAttribute("hidden");

/**
 * Get the number of days in any particular month
 * @link https://stackoverflow.com/a/1433119/1293256
 * @param month The month (January is 0, February 1 etc.)
 * @return The number of days in the month
 */
const daysInMonth = (month: number, year: number) => {
  switch (month) {
    case 1:
      return (year % 4 == 0 && year % 100) || year % 400 == 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
};

/**
 * Check if a date is valid
 * @link https://stackoverflow.com/a/1433119/1293256
 * @param month The month (January is 0, February 1 etc.)
 * @return Returns true if valid
 */
export const isValidDate = (day: number, month: number, year: number) =>
  month >= 0 &&
  month < 12 &&
  day > 0 &&
  day <= daysInMonth(month, year) &&
  year >= 1000;

export const currentLanguage = <Language>(
  document.documentElement.getAttribute("lang")
);

export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

export const translations = languagesJSON[currentLanguage];

export const availableLanguages: Language[] = keys(translations.LANGUAGES);

const getJSONDataFromDOM = <Data>(
  selector: string,
  validate: (input: unknown) => boolean
): Data => {
  const el = document.querySelector(selector);
  if (!el || !el.textContent) {
    throw new Error(`Element ${selector} could not be found.`);
  }

  const data = JSON.parse(el.textContent);

  if (!validate(data)) {
    throw new Error("JSON validation error");
  }

  return data;
};

const checkIsString = (value: unknown) => typeof value === "string";

const validateCmsData = (input: unknown): input is CmsData => {
  const validators: { [key in keyof CmsData]: (value: unknown) => boolean } = {
    transparentPixelUrl: checkIsString,
    themeUrl: checkIsString,
    privacyUrl: checkIsString,
    conditionsOfParticipationUrl: checkIsString,
    courseId: (value) => typeof value === "number" || value === null,
    currentDate: checkIsString,
    courseUrls: (value) => typeof value === "object",
  };

  return keys(validators).every((key) => {
    const validate = validators[key];
    return validate((<CmsData>input)[key]);
  });
};

export const cmsData = getJSONDataFromDOM<CmsData>(
  "#js-cms-data",
  validateCmsData
);

export const isNotEmpty = (input: string | number) => !!input;

export const showsError = ({
  isValid,
  blurFired,
  formInvalid,
}: {
  isValid: boolean;
  blurFired: boolean;
  formInvalid: boolean;
}): boolean => !isValid && (blurFired || formInvalid);

export const requiredFieldValidation = {
  errorMessage: translations.FORMS.FILL_OUT_FIELD,
  func: isNotEmpty,
};

const isValidItalianFiscalCode = (input: string): boolean => {
  const regex = /^[A-Z]{6}[0-9]{2}[A-Z]{1}[0-9]{2}[A-Z0-9]{4}[A-Z]{1}$/;
  return regex.test(input);
};

export const getTaxNumberValidation = ({
  country,
  required,
}: {
  required: boolean;
  country: Country | undefined;
}): FormFieldValidation => ({
  errorMessage:
    country === "ITA"
      ? translations.FORMS.INVALID_ITALIAN_FISCAL_CODE
      : translations.FORMS.FILL_OUT_FIELD,
  func: (value) =>
    (!required && !value) ||
    (country === "ITA" ? isValidItalianFiscalCode(value) : isNotEmpty(value)),
});

export const currentDate = new Date(cmsData.currentDate);

export const getCourseRegistrationAvailable = (
  course: CourseAPIResponse
): boolean => {
  if (!course.registrationAllowed) return false;
  const firstDay = course.days?.[0];
  if (!firstDay) return true;
  return currentDate <= new Date(firstDay);
};

const getCalendarLimits = () => {
  const year = currentDate.getFullYear();
  return {
    min: new Date(year - 1, 0),
    max: new Date(year + 1, 11),
  };
};

export const calendarLimits = getCalendarLimits();

export const truncateString = (input: string, length: number) =>
  input.length <= length ? input : input.slice(0, length) + " …";

export const enquiryTypes = <const>["renting", "teamEvent"];

export let isTouchDevice = false;

const setIsTouchDevice = () => {
  isTouchDevice = true;
  document.body.classList.add("is-touch-device");
  window.removeEventListener("touchstart", setIsTouchDevice);
};

window.addEventListener("touchstart", setIsTouchDevice);

export const targetAudiences = <const>[
  "gourmets-of-tomorrow",
  "taste-professionals",
  "delight-lovers",
];

export const getTargetAudienceColorClass = (
  targetAudiences: TargetAudience[]
): string | undefined => {
  const categoryColorMapping: { [category in TargetAudience]: string } = {
    "delight-lovers": "red",
    "gourmets-of-tomorrow": "green",
    "taste-professionals": "yellow-orange",
  };

  if (targetAudiences.length > 1) return undefined;

  return !targetAudiences[0]
    ? undefined
    : "color-" + categoryColorMapping[targetAudiences[0]];
};

export const formatISODate = (date: Date) => format(date, "yyyy-MM-dd");

export const baguetteBoxOptions = {
  captions: (element: HTMLElement) => {
    const copyright = element
      .querySelector(".js-image-copyright")
      ?.textContent?.trim();
    return element.title + (copyright ? ` (${copyright})` : "");
  },
};

export const getRandomInteger = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
